<template>
  <div class="page TechnologyList">TechnologyList</div>
</template>

<script>
export default {
  name: "TechnologyList",
};
</script>

<style scoped>
.TechnologyList {
  padding: 10px;
}
</style>